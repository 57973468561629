/* ConsumerControl.module.css */
.zp-key-manager--consumer-control-container {
  margin-bottom: 1.25rem;
  border-radius: 0.5rem;
  border-width: 1px;
  border-color: rgb(228 228 231 / 1);
  background-color: rgb(248 250 252 / 1);
}
:is(.zp-key-manager--dark .zp-key-manager--consumer-control-container) {
  border-style: none;
  background-color: rgb(79 86 107 / 1);
}
:is(.zp-key-manager--dark .zp-key-manager--consumer-control-container)::-moz-selection {
  background-color: rgb(79 86 107 / 1);
}
:is(.zp-key-manager--dark .zp-key-manager--consumer-control-container)::selection {
  background-color: rgb(79 86 107 / 1);
}
:is(.zp-key-manager--dark .zp-key-manager--consumer-control-container) *::-moz-selection {
  background-color: rgb(255 255 255 / 1);
  color: rgb(24 24 27 / 1);
}
:is(.zp-key-manager--dark .zp-key-manager--consumer-control-container) *::selection {
  background-color: rgb(255 255 255 / 1);
  color: rgb(24 24 27 / 1);
}
:is(.zp-key-manager--dark .zp-key-manager--consumer-control-container) *::-moz-selection::selection {
  color: rgb(24 24 27 / 1);
}
:is(.zp-key-manager--dark .zp-key-manager--consumer-control-container) *::selection::selection {
  color: rgb(24 24 27 / 1);
}
:is(.zp-key-manager--dark .zp-key-manager--consumer-control-container) *::-moz-selection:hover {
  background-color: rgb(255 255 255 / 1);
}
:is(.zp-key-manager--dark .zp-key-manager--consumer-control-container) *::selection:hover {
  background-color: rgb(255 255 255 / 1);
}
:is(.zp-key-manager--dark .zp-key-manager--consumer-control-container) *::-moz-selection:hover {
  background-color: rgb(255 255 255 / 1);
}
:is(.zp-key-manager--dark .zp-key-manager--consumer-control-container) *::selection:hover {
  background-color: rgb(255 255 255 / 1);
}
:is(.zp-key-manager--dark .zp-key-manager--consumer-control-container) *::-moz-selection:hover {
  background-color: rgb(255 255 255 / 1);
}
:is(.zp-key-manager--dark .zp-key-manager--consumer-control-container) *::selection:hover {
  background-color: rgb(255 255 255 / 1);
}
:is(.zp-key-manager--dark .zp-key-manager--consumer-control-container) *::-moz-selection::selection {
  background-color: rgb(255 255 255 / 1);
}
:is(.zp-key-manager--dark .zp-key-manager--consumer-control-container) *::selection::selection {
  background-color: rgb(255 255 255 / 1);
}
:is(.zp-key-manager--dark .zp-key-manager--consumer-control-container)::-moz-selection {
  background-color: rgb(255 255 255 / 1);
  color: undefined;
}
:is(.zp-key-manager--dark .zp-key-manager--consumer-control-container)::selection {
  background-color: rgb(255 255 255 / 1);
  color: undefined;
}
:is(.zp-key-manager--dark .zp-key-manager--consumer-control-container)::-moz-selection::selection {
  color: rgb(24 24 27 / 1);
}
:is(.zp-key-manager--dark .zp-key-manager--consumer-control-container)::selection::selection {
  color: rgb(24 24 27 / 1);
}
:is(.zp-key-manager--dark .zp-key-manager--consumer-control-container)::-moz-selection::selection {
  background-color: rgb(255 255 255 / 1);
}
:is(.zp-key-manager--dark .zp-key-manager--consumer-control-container)::selection::selection {
  background-color: rgb(255 255 255 / 1);
}

.zp-key-manager--consumer-control-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: 1px;
  border-color: rgb(228 228 231 / 1);
}

:is(.zp-key-manager--dark .zp-key-manager--consumer-control-header) {
  border-style: none;
}

.zp-key-manager--consumer-control-input-container {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.zp-key-manager--consumer-control-input {
  margin: 0.25rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  width: 100%;
  flex: 1 1 0%;
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: rgb(148 163 184 / 1);
  background-color: rgb(255 255 255 / 1);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  box-shadow: undefined, undefined, 0 0 #0000;
}

.zp-key-manager--consumer-control-input:disabled {
  opacity: 0.5;
}

:is(.zp-key-manager--dark .zp-key-manager--consumer-control-input) {
  background-color: rgb(71 85 105 / 1);
  color: rgb(255 255 255 / 1);
}

.zp-key-manager--consumer-control-button {
  margin: 0.25rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  height: 2rem;
  flex-direction: row;
  align-items: center;
  border-radius: 0.25rem;
  background-color: rgb(226 232 240 / 1);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgb(63 63 70 / 1);
}

.zp-key-manager--consumer-control-button:hover {
  background-color: rgb(226 232 240 / 1);
}

.zp-key-manager--consumer-control-button:hover {
  background-color: rgb(203 213 225 / 1);
}

.zp-key-manager--consumer-control-button:hover:hover {
  background-color: rgb(203 213 225 / 1);
}

:is(.zp-key-manager--dark .zp-key-manager--consumer-control-button) {
  background-color: rgb(100 116 139 / 1);
  color: rgb(255 255 255 / 1);
}

:is(.zp-key-manager--dark .zp-key-manager--consumer-control-button):hover {
  background-color: rgb(100 116 139 / 1);
}

:is(.zp-key-manager--dark .zp-key-manager--consumer-control-button):hover {
  background-color: rgb(148 163 184 / 1);
}

:is(.zp-key-manager--dark .zp-key-manager--consumer-control-button):hover:hover {
  background-color: rgb(148 163 184 / 1);
}

.zp-key-manager--consumer-control-menu-spinner-container {
  margin-right: 0.25rem;
  height: 100%;
  padding-bottom: 10px;
  padding-top: 0.25rem;
}

.zp-key-manager--consumer-control-menu-spinner {
  height: 1.25rem;
  width: 1.25rem;
}

@keyframes zp-key-manager--spin {
  to {
    transform: rotate(360deg);
  }
}

.zp-key-manager--consumer-control-menu-spinner {
  animation: zp-key-manager--spin 1s linear infinite;
}

:is(.zp-key-manager--dark .zp-key-manager--consumer-control-menu-spinner) {
  color: rgb(255 255 255 / 1);
}

.zp-key-manager--consumer-control-spinner-container {
  margin-right: 0.25rem;
}

.zp-key-manager--consumer-control-spinner {
  height: 1rem;
  width: 1rem;
}

@keyframes zp-key-manager--spin {
  to {
    transform: rotate(360deg);
  }
}

.zp-key-manager--consumer-control-spinner {
  animation: zp-key-manager--spin 1s linear infinite;
}

:is(.zp-key-manager--dark .zp-key-manager--consumer-control-spinner) {
  color: rgb(255 255 255 / 1);
}

.zp-key-manager--consumer-control-save-icon {
  margin-right: 0.25rem;
  height: 1rem;
  width: auto;
}

.zp-key-manager--consumer-control-cancel-icon {
  margin-right: 0.25rem;
  height: 1rem;
  width: auto;
}

.zp-key-manager--consumer-control-description {
  margin-left: 1rem;
  color: rgb(24 24 27 / 1);
}

:is(.zp-key-manager--dark .zp-key-manager--consumer-control-description) {
  color: rgb(255 255 255 / 1);
}

.zp-key-manager--consumer-menu-button-wrapper {
  margin: 0.5rem;
  margin-top: 1rem;
  margin-right: 0.75rem;
}

.zp-key-manager--consumer-control-menu-button {
  border-radius: 0.25rem;
  padding: 0.25rem;
}

.zp-key-manager--consumer-control-menu-button:hover {
  background-color: undefined;
}

.zp-key-manager--consumer-control-menu-button:hover:hover {
  background-color: rgb(226 232 240 / 1);
}

.zp-key-manager--dark .zp-key-manager--consumer-control-menu-button:hover {
  background-color: #697386;
}

.zp-key-manager--consumer-control-menu-icon {
  height: 1.25rem;
  width: 1.25rem;
  color: rgb(113 113 122 / 1);
}

:is(.zp-key-manager--dark .zp-key-manager--consumer-control-menu-icon) {
  color: rgb(255 255 255 / 1);
}

.zp-key-manager--consumer-control-error-container {
  background-color: rgb(254 242 242 / 1);
  padding: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgb(220 38 38 / 1);
}

.zp-key-manager--consumer-control-error-header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.zp-key-manager--consumer-control-error-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.zp-key-manager--consumer-control-error-icon {
  margin-right: 0.25rem;
  height: 1rem;
  width: 1rem;
}

.zp-key-manager--consumer-control-error-leading-text {
  font-weight: 700;
}

.zp-key-manager--consumer-control-error-dismiss {
  border-radius: 0.25rem;
  padding: 0.5rem;
  color: rgb(63 63 70 / 1);
}

.zp-key-manager--consumer-control-error-dismiss:hover {
  background-color: undefined;
}

.zp-key-manager--consumer-control-error-dismiss:hover:hover {
  background-color: rgb(254 226 226 / 1);
}

.zp-key-manager--consumer-control-error-dismiss-icon {
  height: 1rem;
  width: 1rem;
}

.zp-key-manager--consumer-control-error-text {
  padding-left: 1.25rem;
  color: rgb(239 68 68 / 1);
}

.zp-key-manager--consumer-control-content {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  background-color: rgb(255 255 255 / 1);
  padding: 1rem;
}

:is(.zp-key-manager--dark .zp-key-manager--consumer-control-content) {
  background-color: rgb(42 47 69 / 1);
}

.zp-key-manager--consumer-control-key-control {
  margin-bottom: 0.75rem;
}
