@tailwind base;
@tailwind components;
@tailwind utilities;
@import "@zuplo/react-api-key-manager/tailwind.css";

@layer {
  h1 {
    @apply text-2xl;
    @apply text-strong-text;
  }

  h2 {
    @apply text-xl;
    @apply text-strong-text;
  }

  body {
    @apply text-base;
    @apply text-mild-text;
    @apply font-primary;
    @apply font-normal;
    @apply antialiased;
    @apply selection:bg-text-selection-background;
    @apply selection:text-[#4F566B];
  }
}
