:root {
  --logo-url: url(https://cdn.zuplo.com/dev-portal/v2/made-with-zuplo.svg);
}

.light {
  --background-auth-actions: #e2e8f1;
  --background-button-primary: #556cd6;
  --background-button-secondary: #f7fafc;
  --background-code-sample-header-primary: #3c4257;
  --background-code-sample-header-secondary: #4f566b;
  --background-example-section-header-primary: #e2e8f1;
  --background-example-section-header-secondary: #ccd5e1;
  --background-inline-code: #f7fafc;
  --background-input: #f7fafc;
  --background-input-hovered: #f7fafc;
  --background-nav-item-selected: #f5fbff;
  --background-primary: white;
  --background-text-selection-color: #accef7;
  --border-inline-code: rgba(0, 0, 0, 0.05);
  --border-input: rgba(0, 0, 0, 0.07);
  --border-input-hovered: rgb(163 172 185);
  --color-divider: #e5e7eb;
  --color-nav-divider: #e5e7eb;
  --font-family-primary: "-apple-system", "BlinkMacSystemFont", "Segoe UI",
    "Roboto", "Helvetica Neue", "Ubuntu", "sans-serif";
  --text-color-code-sample-header: #c1c9d2;
  --text-color-enum-header: #697386;
  --text-color-example-section-header: #63748b;
  --text-color-h1: rgb(42 47 69);
  --text-color-h2: rgb(42 47 69);
  --text-color-h3: rgb(42 47 69);
  --text-color-inline-code: #2a2f45;
  --text-color-nav: #697386;
  --text-color-nav-hovered: #2a2f45;
  --text-color-nav-selected: #566cd6;
  --text-color-primary: rgb(79 86 107);
  --text-color-property-constraints: #8792a2;
  --text-color-property-name: #3c4257;
  --text-color-placeholder-hovered: #a3acb9;
  --text-color-required: #e56f4a;
  --text-selection-color: #4f566b;
  /* Undocumented on purpose - Not ready for customers */
  --background-example-code-sample: #f7fafc;
  --background-request-code-sample: #4f566b;
  --shadow-color: black/50;
  --text-color-href: #556cd6;
  --color-sidebar: #c1c9d2;
}

.dark {
  --background-auth-actions: #3c4257;
  --background-button-primary: #556cd6;
  --background-button-secondary: hsla(0, 0%, 100%, 0.04);
  --background-code-sample-header-primary: #4f566b;
  --background-code-sample-header-secondary: #697386;
  --background-example-section-header-primary: #3c4257;
  --background-example-section-header-secondary: #4f566b;
  --background-inline-code: hsla(0, 0%, 100%, 0.04);
  --background-input: #2a2f45;
  --background-input-hovered: #212d63;
  --background-nav-item-selected: #2a2f45;
  --background-primary: #1a1f36;
  --background-text-selection-color: white;
  --border-inline-code: hsla(0, 0%, 100%, 0.08);
  --border-input: hsla(0, 0%, 100%, 0.15);
  --border-input-hovered: rgb(163 172 185);
  --color-divider: #3c4257;
  --color-nav-divider: #3c4257;
  --font-family-primary: "-apple-system", "BlinkMacSystemFont", "Segoe UI",
    "Roboto", "Helvetica Neue", "Ubuntu", "sans-serif";
  --text-color-code-sample-header: #a3acb9;
  --text-color-enum-header: #a3acb9;
  --text-color-example-section-header: #a3acb9;
  --text-color-h1: #f7fafc;
  --text-color-h2: #f7fafc;
  --text-color-h3: #f7fafc;
  --text-color-inline-code: #f7fafc;
  --text-color-nav: #a3acb9;
  --text-color-nav-hovered: #f7fafc;
  --text-color-nav-selected: #6c8eef;
  --text-color-primary: rgb(193 201 210);
  --text-color-property-constraints: #8792a2;
  --text-color-property-name: #e3e8ee;
  --text-color-placeholder-hovered: #a3acb9;
  --text-color-required: #c44c34;
  --text-selection-color: #4f566b;
  /* Undocumented on Purpose - Not ready for customers */
  --background-example-code-sample: #2a2f45;
  --background-request-code-sample: #3c4257;
  --shadow-color: white;
  --text-color-href: #6c8eef;
  --color-sidebar: #4f566b;
}

/* The logo image */
.theme-logo {
  content: var(--logo-url);
  margin: 20px;
}

.scrollbar::-webkit-scrollbar {
  padding: 2px;
  width: 12px;
  height: 12px;
  background: transparent;
}

.scrollbar:hover::-webkit-scrollbar-thumb {
  background: var(--color-sidebar);
  border-radius: 12px;
  border: 2px solid transparent;
  background-clip: content-box;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
}

.scrollbar::-webkit-scrollbar-corner {
  background: transparent;
}
