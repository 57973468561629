/**
 * Nord Theme Originally by Arctic Ice Studio
 * https://nordtheme.com
 *
 * Ported for PrismJS by Zane Hitchcoxc (@zwhitchcox) and Gabriel Ramos (@gabrieluizramos)
 */

code[class*="language-default-"],
pre[class*="language-default-"] {
  color: #f8f8f2;

  /* 
   * TODO: This looks terrible, but I couldn't figure 
   * out how to get the overflow to work correctly.
   */
  white-space: pre-wrap;
  @apply text-xs;
}

/* Custom styles for API reference examples */
pre[class*="language-default-"].api-reference-light,
pre[class*="language-default-"].api-reference-dark {
  margin-top: 0;
  border-radius: 0px 0px 5px 5px;
  max-height: 600px;
}

/* Code blocks */
pre[class*="language-default-"] {
  padding: 1em;
  margin: 0;
  overflow-x: auto;
  overflow-y: auto;
  border-radius: 0.3em;
}

pre[class*="language-default-"]::-webkit-scrollbar {
  padding: 2px;
  width: 12px;
  height: 12px;
  background: transparent;
}

pre[class*="language-default-"]:hover::-webkit-scrollbar-thumb {
  background: var(--color-sidebar);
  border-radius: 12px;
  border: 2px solid transparent;
  background-clip: content-box;
}

pre[class*="language-default-"]::-webkit-scrollbar-thumb {
  background: transparent;
}

pre[class*="language-default-"]::-webkit-scrollbar-corner {
  background: transparent;
}

:not(pre) > code[class*="language-default-"],
pre[class*="language-default-"] {
  background: var(--background-request-code-sample);
}

/* Inline code */
:not(pre) > code[class*="language-default-"] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #636f88;
}

.token.punctuation {
  color: #81a1c1;
}

.namespace {
  opacity: 0.7;
}

.token.property,
.token.tag,
.token.constant,
.token.symbol,
.token.deleted {
  color: #81a1c1;
}

.token.number {
  color: #b48ead;
}

.token.boolean {
  color: #81a1c1;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #a3be8c;
}

.token.operator,
.token.entity,
.token.url,
.language-default-css .token.string,
.style .token.string,
.token.variable {
  color: #81a1c1;
}

.token.atrule,
.token.attr-value,
.token.function,
.token.class-name {
  color: #88c0d0;
}

.token.keyword {
  color: #81a1c1;
}

.token.regex,
.token.important {
  color: #ebcb8b;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}
