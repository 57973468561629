/* PrismJS 1.29.0
https://prismjs.com/download.html#themes=prism&languages=markup+css */
/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 * Edits made to better approximate Stripe Response example in their docs
 */

/* Custom styles for API examples */
pre[class*="language-example-"].api-example-dark {
  margin-top: 0;
  border-radius: 0px 0px 0.375rem 0.375rem;
  max-height: calc(100vh - 315px);
}

/* Custom Scrollbar styling */
@media (min-width: 1024px) {
  pre[class*="language-example-"].api-example-dark::-webkit-scrollbar {
    padding: 2px;
    width: 12px;
    height: 12px;
    background: transparent;
  }

  pre[class*="language-example-"].api-example-dark:hover::-webkit-scrollbar-thumb {
    background: var(--color-sidebar);
    border-radius: 12px;
    border: 2px solid transparent;
    background-clip: content-box;
  }

  pre[class*="language-example-"].api-example-dark::-webkit-scrollbar-thumb {
    background: transparent;
  }

  pre[class*="language-example-"].api-example-dark::-webkit-scrollbar-corner {
    background: transparent;
  }
}

:not(pre) > code[class*="language-example-"].api-example-dark,
pre[class*="language-example-"].api-example-dark {
  background: var(--background-example-code-sample);
}

.api-example-dark .token.comment,
.api-example-dark .token.prolog,
.api-example-dark .token.doctype,
.api-example-dark .token.cdata {
  color: slategray;
}

.api-example-dark .token.punctuation {
  color: #999;
}

.api-example-dark .token.namespace {
  opacity: 0.7;
}

.api-example-dark .token.property,
.api-example-dark .token.tag,
.api-example-dark .token.constant,
.api-example-dark .token.symbol,
.api-example-dark .token.deleted {
  color: #905;
}

/* Approximation of Stripe */
.api-example-dark .token.number {
  color: #e56f4a;
}

.api-example-dark .token.property {
  color: #a3acb9;
}

.api-example-dark .token.boolean {
  color: #6c8eef;
  font-weight: 600;
}

.api-example-dark .token.selector,
.api-example-dark .token.string,
.api-example-dark .token.char,
.api-example-dark .token.builtin,
.api-example-dark .token.attr-name,
.api-example-dark .token.inserted {
  color: #1ea672;
}

.api-example-dark .token.operator,
.api-example-dark .token.entity,
.api-example-dark .token.url,
.language-example-css .token.string,
.style .api-example-dark .token.string {
  color: #8792a2;
}

.api-example-dark .token.atrule,
.api-example-dark .token.attr-value,
.api-example-dark .token.keyword {
  color: #8792a2;
}

.api-example-dark .token.function,
.api-example-dark .token.class-name {
  color: #dd4a68;
}

.api-example-dark .token.regex,
.api-example-dark .token.important,
.api-example-dark .token.variable {
  color: #e90;
}

.api-example-dark .token.important,
.api-example-dark .token.bold {
  font-weight: bold;
}
.api-example-dark .token.italic {
  font-style: italic;
}

.api-example-dark .token.entity {
  cursor: help;
}
