/* PrismJS 1.29.0
https://prismjs.com/download.html#themes=prism&languages=markup+css */
/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 * Edits made to better approximate Stripe Response example in their docs
 */

code[class*="language-example-"],
pre[class*="language-example-"] {
  color: black;

  white-space: pre;
  @apply text-xs;

  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;

  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

/* Custom styles for API reference examples */
pre[class*="language-example-"].api-example-light {
  margin-top: 0;
  border-radius: 0px 0px 0.375rem 0.375rem;
  max-height: calc(100vh - 315px);
}

pre[class*="language-example-"]::-moz-selection,
pre[class*="language-example-"] ::-moz-selection,
code[class*="language-example-"]::-moz-selection,
code[class*="language-example-"] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

pre[class*="language-example-"]::selection,
pre[class*="language-example-"] ::selection,
code[class*="language-example-"]::selection,
code[class*="language-example-"] ::selection {
  text-shadow: none;
  background: #b3d4fc;
}

@media print {
  code[class*="language-example-"],
  pre[class*="language-example-"] {
    text-shadow: none;
  }
}

/* Code blocks */
pre[class*="language-example-"] {
  padding: 1em;
  overflow-y: auto;
  overflow-x: auto;
}

/* Custom Scrollbar styling */
@media (min-width: 1024px) {
  pre[class*="language-example-"].api-example-light::-webkit-scrollbar {
    padding: 2px;
    width: 12px;
    height: 12px;
    background: transparent;
  }
  pre[class*="language-example-"].api-example-light:hover::-webkit-scrollbar-thumb {
    background: var(--color-sidebar);
    border-radius: 12px;
    border: 2px solid transparent;
    background-clip: content-box;
  }

  pre[class*="language-example-"].api-example-light::-webkit-scrollbar-thumb {
    background: transparent;
  }

  pre[class*="language-example-"].api-example-light::-webkit-scrollbar-corner {
    background: transparent;
  }
}

:not(pre) > code[class*="language-example-"].api-example-light,
pre[class*="language-example-"].api-example-light {
  background: var(--background-example-code-sample);
}

/* Inline code */
:not(pre) > code[class*="language-example-"] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.api-example-light .token.comment,
.api-example-light .token.prolog,
.api-example-light .token.doctype,
.api-example-light .token.cdata {
  color: slategray;
}

.api-example-light .token.punctuation {
  color: #999;
}

.api-example-light .token.namespace {
  opacity: 0.7;
}

.api-example-light .token.property,
.api-example-light .token.tag,
.api-example-light .token.constant,
.api-example-light .token.symbol,
.api-example-light .token.deleted {
  color: #905;
}

/* Approximation of Stripe */
.api-example-light .token.number {
  color: #e56f4a;
}

.api-example-light .token.property {
  color: #697386;
}

.api-example-light .token.boolean {
  color: #067ab8;
  font-weight: 600;
}

.api-example-light .token.selector,
.api-example-light .token.string,
.api-example-light .token.char,
.api-example-light .token.builtin,
.api-example-light .token.attr-name,
.api-example-light .token.inserted {
  color: #09825d;
}

.api-example-light .token.operator,
.api-example-light .token.entity,
.api-example-light .token.url,
.language-example-css .token.string,
.style .api-example-light .token.string {
  color: #a3acb9;
}

.api-example-light .token.atrule,
.api-example-light .token.attr-value,
.api-example-light .token.keyword {
  color: #8792a2;
}

.api-example-light .token.function,
.api-example-light .token.class-name {
  color: #dd4a68;
}

.api-example-light .token.regex,
.api-example-light .token.important,
.api-example-light .token.variable {
  color: #e90;
}

.api-example-light .token.important,
.api-example-light .token.bold {
  font-weight: bold;
}
.api-example-light .token.italic {
  font-style: italic;
}

.api-example-light .token.entity {
  cursor: help;
}
